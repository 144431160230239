import React from 'react'
import logo from '../img/Bhakti Culture Curv logo.png'
import { FaBehance, FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa'

export default function Footer() {
    return (
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="first-item">
                            <div class="logo">
                                <img src={logo} className='logo' alt="hexashop ecommerce templatemo" />
                            </div>
                            <ul>
                                <li><a href="#">SHOP NO.88-89,  AVADH TEXTILE MARKET, UMARWADA, SAHARA DARWAJA,  OPP NEW BOMBAY MARKET, SURAT - 395101</a></li>
                                <li><a href="#">info@bhakticulture.in</a></li>
                                <li><a href="#">97278 28326</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <h4>Useful Links</h4>
                        <ul>
                            <li><a href="/">Homepage</a></li>
                            <li><a href="/about">About Us</a></li>

                        </ul>
                    </div>

                    <div class="col-lg-12">
                        <div class="under-footer">
                            <p>Copyright © 2024 <b>BHAKTI CULTURE</b> All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
