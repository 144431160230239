import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LandingPage from './LandingPage'
import About from './About'

export default function RouterContainer() {
    return (
        <BrowserRouter>

            <Routes>
                <Route path={'/'} element={<LandingPage />} />
                <Route path={'/about'} element={<About />} />

            </Routes>
        </BrowserRouter>
    )
}
