import React from 'react'
import logo from '../img/Bhakti Culture Curv logo.png'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Header() {
    return (
        <Navbar sticky='top' expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="/"><img src={logo} className='logo' /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">

                        <Nav.Link>  <Link className='text-dark ms-4' to='/'>Home</Link> </Nav.Link>
                        <Nav.Link > <Link className='text-dark ms-4' to='/about'>About us</Link></Nav.Link>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
