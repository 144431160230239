import React, { useEffect } from 'react'
import RouterContainer from './RouterContainer'
import Aos from 'aos';


import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css'
import 'aos/dist/aos.css';
import './LandingPage.css'

export default function App() {

  useEffect(() => {
    Aos.init();
  }, [])

  return (
    <RouterContainer />
  )
}
