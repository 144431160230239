import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import img1 from './img/gallary-1.jpg'
import Layout from './Components/Layout'
import men1 from './modeling/img-3.jpg'
import women1 from './modeling/img-1.jpg'
export default function About() {
    return (
        <>
            <Layout>

                <Container className='mt-5'>

                    <Row>
                        <Col lg={6} data-aos="fade-right">
                            <img src={img1} className='img-fluid' alt='about us' />
                        </Col>
                        <Col lg={6} data-aos="fade-left" className='left-content'>
                            <h2>About Us</h2>
                            <p className='text-justify mt-3'>
                                Welcome to <b>Bhakti Culture</b>, where tradition meets modern craftsmanship. We are proud manufacturers of premium women’s ethnic wear, specializing in beautifully crafted Kurti Pant Dupatta sets and elegant sarees. Our mission is to bring the rich heritage of Indian fashion to the forefront, blending timeless designs with contemporary styles to create outfits that make every woman feel special.
                            </p>
                            <p className='text-justify'>   At <b>Bhakti Culture</b>, we focus on quality and attention to detail, ensuring each piece reflects our commitment to excellence. We cater exclusively to wholesalers and resellers, offering a wide range of affordable, stylish, and high-quality ethnic wear collections that appeal to diverse tastes and preferences.
                            </p>
                            <p className='text-justify'>    Our dedication to customer satisfaction, efficient service, and on-time delivery has earned us the trust of our partners across the fashion industry. Whether you're looking to expand your collection or offer the latest trends to your clients, we’re here to help you succeed with every transaction.
                            </p>
                            <p className='text-justify'>     Thank you for choosing <b>Bhakti Culture</b>. We look forward to partnering with you and contributing to the success of your business.
                            </p>
                        </Col>
                    </Row>


                    <section class="section" id="explore">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-6" data-aos="fade-right">
                                    <div class="left-content">
                                        <h2>Why Choose Us?</h2>
                                        <p className='text-dark'>1. What makes you a good choice for my ethnic wear business?</p>
                                        <span> We are a leading manufacturer, wholesaler, and stockist of women’s ethnic wear, offering a wide range of high-quality Kurtis, Pant Dupatta sets, and sarees. We make it easy for you to start or grow your business by providing flexibility, competitive pricing, and a wide variety of products.</span>
                                        <p className='text-dark'>2. Can I start my own business by reselling your products?</p>
                                        <span>   Yes! You can easily start your own reselling business with us. We offer a wide range of trendy and high-quality ethnic wear that will appeal to your customers, making it a great opportunity to enter the market.</span>
                                        <p className='text-dark'>3. Do I need to buy products in bulk to get started?</p>
                                        <span>  No, you don't need to invest in large quantities. We offer an order-based purchasing system, which means you can buy stock only as you need it. Our minimum order quantity is just 1 piece, allowing you to keep inventory costs low and reduce business risk.</span>
                                        <p className='text-dark'>4. What is the process for placing orders?</p>
                                        <span>    Simply place your order as needed, and we’ll fulfill it for you. With no minimum stock requirements beyond 1 piece, you have the freedom to manage your inventory as you grow.</span>


                                        <div class="main-border-button">
                                            <a href="/about">Discover More</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6" data-aos="fade-left">
                                    <div class="right-content" >
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="leather">
                                                    <h4>Sarees </h4>
                                                    <span>Latest Collection</span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="first-image">
                                                    <img src={men1} className='img-fluid' alt="" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="second-image">
                                                    <img src={women1} alt="" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="types">
                                                    <h4>Kurtis</h4>
                                                    <span>Over 304 Products</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Container>
            </Layout>

        </>
    )
}
